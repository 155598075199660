var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ekhaya@3.6.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/ekhaya";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { getClientsideEnvironmentId } from '@sbt-web/utils';
import { isUnsupportedBrowser } from '@sbt-web/browserlist-regexp';
import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production' && isUnsupportedBrowser() !== true) {
  Sentry.init({
    ignoreErrors: [
      /pulse-sdk/gi,
      //unhandled errors: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      //Adv chunk:
      'Loading chunk 175 failed',
      //Syntax error (legacy browser):
      "Unexpected token 'else'",
      'Unexpected end of input',
      'Unexpected identifier',
      "Unexpected token '<'",
      //Network errors:
      'Request aborted',
      'Request failed',
      'Network Error',
      'Load failed',
      //@spt-tracking
      'Illegal invocation',
      'ResizeObserver loop limit exceeded',
    ],
    denyUrls: [
      /_next\/static\/chunks\/prebid/i,
      /gtag\/js/i,
      /sdk.privacy-center.org/i,
      // Ignoring this error from appflyer when the page is opened into a webview
      // https://subitoit.sentry.io/issues/2581809269/?project=1724215&referrer=project-issue-stream
      /st=banners/i,
      /tag\/js\/gpt.js/i,
      /s.pinimg.com/i,
    ],
    dsn: process.env.SENTRY_DSN as string,
    //By default, we track all errors and no transactions are prioritised for high-traffic public pages.
    sampleRate: 1,
    tracesSampleRate: 0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT,
  });

  const envId = getClientsideEnvironmentId();

  if (envId != null) {
    Sentry.setUser({ id: envId });
  }
}
